import { useEffect } from "react";

const TestButton = () => {
  
  useEffect(() => {
    console.log(document.cookie)

    const ideal_localStorage_on_ideal_domain = localStorage.getItem('localStorage_on_ideal_domain')
    const ideal_localStorage_on_ec_domain_a = localStorage.getItem('localStorage_on_ec_domain_a')
    console.log({ideal_localStorage_on_ideal_domain})
    console.log({ideal_localStorage_on_ec_domain_a})

    // const ideal_documentCookie = document.cookie
    // console.log({ideal_documentCookie})
    // // localStorage.setItem('localStorage_on_ideal_domain', 'true')
    // const ideal_localStorage_on_ideal_domain = localStorage.getItem('localStorage_on_ideal_domain')
    // const ideal_localStorage_on_ec_domain_a = localStorage.getItem('localStorage_on_ec_domain_a')
    // console.log({ideal_localStorage_on_ideal_domain})
    // console.log({ideal_localStorage_on_ec_domain_a})
  }, [])
  // const url = 'https://api.defunty.dev'
  const url = 'https://flfjrj5fceukzrewij6cjc45wa0jsmyp.lambda-url.ap-northeast-1.on.aws/'
  async function asyncCall() {
    console.log('calling');
    const response = await fetch(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      credentials: "include",
      mode: "cors"
    });
    console.log({response})
    if (response) {
      document.cookie = 'from_auth_domain=true; Secure; SameSite=Strict'
      document.cookie = 'from_auth_domain_unsecure=true;'
      document.cookie = 'from_auth_domain_secure_none=true; Secure; SameSite-None'

      const ideal_documentCookie = document.cookie
      console.log({ideal_documentCookie})
      localStorage.setItem('localStorage_on_ideal_domain', 'true')
    } else {
      console.error('no response')
    }
  }

  return (
    <button onClick={() => asyncCall()}>Test Button</button>
  );
}

export default TestButton;