import Profile from "./Profile";
import LoginButton from "./LoginButton";
import TestButton from "./TestButton";
import LogoutButton from "./LogoutButton";

export function App() {
  console.log('App20230923 18:34')
  return (
    <div>
      <h2>プラグイン</h2>
      <div>
        <LoginButton />
        <LogoutButton />
        <Profile />
        {/* <TestButton /> */}
        {/* <iframe src="./iframe.html"></iframe> */}
        {/* <iframe src="https://plugin.defunty.dev/iframe.html"></iframe> */}
      </div>
    </div>
  )
}
