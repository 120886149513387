import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const { loginWithPopup, loginWithRedirect } = useAuth0();

  const redirectToLogin = (redirectUri) => {
    console.log('https://defunty.dev')
    // loginWithRedirect({
    //   redirectUri: 'https://defunty.dev',
    //   appState: {
    //     returnTo: redirectUri
    //   }
    // })
    loginWithPopup()
  }

  return <button onClick={() => redirectToLogin('http://localhost:1234/test')}>Log In</button>;
};

export default LoginButton;
