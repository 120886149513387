import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
  const { logout } = useAuth0();

  const logoutHandle = () => {
    logout()
  }

  return <button onClick={() => logoutHandle()}>Log Out</button>;
};

export default LogoutButton;
