import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { createRoot } from "react-dom/client";
import { App } from "./App";

class Plugin extends HTMLElement {
  connectedCallback() {
    const mountPoint = document.createElement('span');
    this.attachShadow({ mode: 'open' }).appendChild(mountPoint);

    const root = createRoot(mountPoint);

    // const {appState} = useAuth0();
    // root.render(<div>hoge</div>)
    const onRedirectCallback = (appState) => {
      console.log({appState})
      // Use the router's history module to replace the url
      // window.location.href = ('http://localhost:1234');
    }

    root.render(
      <Auth0Provider
        domain="dev-kzen14vm.jp.auth0.com"
        clientId="IcLtNxlSbo317d5SNGtooLWnVXYeMKLt"
        cacheLocation={'localstorage'}
        useRefreshTokens={true}
        authorizationParams={{
          redirect_uri: 'https://defunty.dev/',
          // redirect_uri: 'http://localhost:1234/',
        }}
        onRedirectCallback={onRedirectCallback}
      >
        <App />
      </Auth0Provider>,
    );
  }
}
customElements.define('my-plugin', Plugin);
